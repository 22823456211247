<template>
    <div>
    	<div>
    		<div class="col-12 mb-2">
                <label class="col-form-label">{{ $t("tiers.recherche_pappers") }}</label>
                <e-select
                    track-by="id"
                    label="label"
                    v-model="searchValue"
                    :options="pappers_result"
                    :placeholder="$t('global.rechercher')"
                    :loading="loading_pappers"
                    :sortable="false"
                    :internal-search="false"
                    preserve-search
                    reset-after
                    @search-change="onSearch"
                    @select="searchPappers"
                    ref="input"
                >
                    <template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                </e-select>
            </div>

            <div class="text-center">
                <b-button variant="primary" @click.prevent="pappers = false"> {{ $t("action.ajouter_manuel") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
            </div>
    	</div>
        <form v-if="!pappers" @submit.prevent="checkForm">
			<div class="row">
                <div class="col-12" v-if="form_message !== ''">
                    <ErrorAlert :messageI18n="form_message" />
                </div>

                <div class="col-12">
                    <div class="form-group" v-if="form.tiers_moral == true">
                        <b-form-checkbox
                            v-model="form.tiers_moral"
                            :unchecked-value="false"
                        >
                        {{ $t('tiers.is_moral') }}
                        </b-form-checkbox>
                    </div>
                    <div class="form-group" v-else>
                        <b-form-checkbox
                            v-model="form.tiers_moral"
                            :unchecked-value="true"
                        >
                        {{ $t('tiers.is_moral') }}
                        </b-form-checkbox>
                    </div>
                </div>
				<div class="col-12" v-if="form.tiers_moral">
                    <div class="form-group">
                        <label for="tiers_rs" class="col-form-label">{{ $t("tiers.rs") }} *</label>
                        <input type="text" id="tiers_rs" class="form-control" v-model="form.tiers_rs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur rs') > -1 }">
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="form-group">
                        <label for="tiers_civility" class="col-form-label">{{ $t("tiers.civility") }} *</label>
                        <e-select
                            id="tiers_civility"
                            v-model="form.tiers_civility"
                            :options="civilities"
                            :allow-empty="false"
                            :show-labels="false"
                            :class="{ 'is-invalid': error && error.indexOf('Erreur civilité') > -1 }"
                        />
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="tiers_firstname" class="col-form-label">{{ $t("tiers.firstname") }} *</label>
                                <input type="text" id="tiers_firstname" class="form-control" v-model="form.tiers_firstname" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur prénom') > -1 }">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="tiers_lastname" class="col-form-label">{{ $t("tiers.lastname") }} *</label>
                                <input type="text" id="tiers_lastname" class="form-control" v-model="form.tiers_lastname" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur nom') > -1 }">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="tiers_alias" class="col-form-label">{{ $t("tiers.alias") }}</label>
                        <input type="text" id="tiers_alias" class="form-control" v-model="form.tiers_alias" required  autocomplete="chrome-off">
                    </div>
                </div>
            </div>
                
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="tiers_address1" class="col-form-label">{{ $t("tiers.address1") }}</label>
                        <input type="text" id="tiers_address1" class="form-control" v-model="form.tiers_address1" required  autocomplete="chrome-off">
                        
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="tiers_address2" class="col-form-label">{{ $t("tiers.address2") }}</label>
                        <input type="text" id="tiers_address2" class="form-control" v-model="form.tiers_address2" required  autocomplete="chrome-off">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="tiers_postalcode" class="col-form-label">{{ $t("tiers.postalcode") }}</label>
                        <input type="text" id="tiers_postalcode" class="form-control" v-if="form.tiers_postalcode" v-model="form.tiers_postalcode" required  autocomplete="chrome-off" @click="resetPostalcode">
                        <SearchInputCommune v-if="!form.tiers_postalcode" :town="0" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
                    </div>
                </div>
                <div class="col-8">
                    <div class="form-group">
                        <label for="tiers_town" class="col-form-label">{{ $t("tiers.town") }}</label>
                        <input type="text" id="tiers_town" class="form-control" v-if="form.tiers_town" v-model="form.tiers_town" required autocomplete="chrome-off" @click="resetTown">
                        <SearchInputCommune v-if="!form.tiers_town" :town="1" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="tiers_country" class="col-form-label">{{ $t("tiers.country") }} *</label>
                        <CountryInput v-model="form.tiers_country"></CountryInput>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="tiers_mail" class="col-form-label">{{ $t("tiers.email") }}</label>
                        <input type="email" id="tiers_mail" class="form-control" v-model="form.tiers_mail"  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur mail') > -1 }">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="col-form-label">{{ $t("tiers.phone") }}</label>
                </div>
                <div class="col-12 phone mb-3" v-for="(phone, key_phone) in form.phones" v-bind:key="key_phone">
                    <div class="row no-gutters">
                        <div class="col-auto pr-2">
                            <phoneInput v-model="form.phones[key_phone]"></phoneInput>
                        </div>
                        <div class="col pr-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif"/>
                                </div>
                                <input type="text" class="form-control" v-model="phone.phone_numero" required autocomplete="chrome-off">
                            </div>
                        </div>
                        <div class="col-3 pr-2">
                            <e-select
                                id="phone_type"
                                v-model="phone.phone_type"
                                :options="[
                                    'fixe',
                                    'portable'
                                ]"
                                :allow-empty="false"
                                :show-labels="false"
                            />
                        </div>
                        <div class="col-3">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="phone.phone_libelle" placeholder="Libellé">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <a href="#" @click.prevent="addPhoneNumber()">{{ $t('global.ajouter_numero_telephone') }} <font-awesome-icon :icon="['fal', 'phone-plus']"/></a>
                </div>
            </div>
            <div class="text-center">
                <b-button variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
            </div>
        </form>
    </div>
</template>

<script type="text/javascript">
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Tiers from "@/mixins/Tiers.js"
    import _debounce from 'lodash/debounce'
    import Countries from '@/assets/lang/countries/countries'
    import cloneDeep from 'lodash/cloneDeep'
    import Common from '@/assets/js/common.js'
    import Shutter from '@/mixins/Shutter.js'

    export default {
        name: "AccountingPappers",
        mixins: [Accounting, AccountingAccount, Tiers, Shutter],
        props: ['code_onboarding'],
        data () {
            return {
            	processing: false,
				countries: {},
				country_choosen: false,
                civilities: Common.getAllCivilities(),
                form: {},
				default_form: {
					tiers_rs: '',
					tiers_firstname: '',
					tiers_lastname: '',
					tiers_civility: this.getTrad('civility.m'),
					tiers_mail: '',
					tiers_alias: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_country: '75',
					tiers_website: '',
					tiers_entity: false,
					tiers_moral: true,
					tiers_currency: 'EUR',
					tiers_lang: 'fr',
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: 'fixe',
							phone_country: "FRA",
                            phone_libelle: ""
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: 'portable',
							phone_country: "FRA",
                            phone_libelle: ""
						}
					]
				},
                pappers: true,
                pappers_result: [],
                loading_pappers: false,
                debouncedSearch: _debounce(this.search, 300),
                searchValue: '',
                error: [],
                form_message: ""
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.form = this.deppCloneObj(this.default_form)
            },

            onSearch(search_val) {
                this.debouncedSearch(search_val)
            },

            async search(search_val) {
                if(!search_val) {
                    return
                }

                let type = "RS"
                this.loading_pappers = true

                if (!isNaN(search_val)){
                    if(search_val.length == 9){
                        type = "SIREN"
                    }else if(search_val.length == 14){
                        type = "SIRET"
                    }
                }
                const pappers = this.searchAPIPappers(search_val, type, true)
                    .then(res => {
                        if(res.resultats) {

                            this.pappers_result = res.resultats.map(tiers => {
                                return {
                                    tiers_rs: tiers.nom_entreprise,
                                    tiers_postalcode: tiers.siege.code_postal,
                                    tiers_address1: tiers.siege.adresse_ligne_1,
                                    tiers_address2: tiers.siege.adresse_ligne_2,
                                    tiers_town: tiers.siege.ville,
                                    tiers_siret: tiers.siege.siret,

                                }
                            })
                        }
                    })

                // Ajout des résultats au e-select
                await Promise.all([pappers])

                this.loading_pappers = false
            },
            async searchPappers(option) {
            	this.pappers = false
            	this.form.tiers_moral = true
            	this.form.tiers_entity = true
                this.form.tiers_rs = option.tiers_rs
                this.form.tiers_address2 = option.tiers_address2
                this.form.tiers_address1 = option.tiers_address1
                this.form.tiers_town = option.tiers_town
                this.form.tiers_postalcode = option.tiers_postalcode
                this.form.tiers_siret = option.tiers_siret
            },
            checkForm: async function () {
				if(!this.processing)
				{
					this.processing = true

					this.form_message = ""
					this.error = []

					if(this.form.tiers_moral == true) {
						if(!this.form.tiers_rs) { 
							this.error.push("Erreur rs") 
						}
					}
					else {
						// if(!this.form.tiers_firstname){
						// 	this.error.push("Erreur prénom") 
						// }
						if(!this.form.tiers_lastname){
							this.error.push("Erreur nom") 
						}
						if(!this.form.tiers_civility){
							this.error.push("Erreur civilité") 
						}
					}

					if(this.form.tiers_mail && !this.form.tiers_mail.match(this.regex_email)) {
						this.error.push('Erreur mail')
					}

					if(!this.form.tiers_country) {
						this.error.push("Erreur pays")
					}

					if(this.error.length == 0) {
                        this.form.tiers_civility = Common.manageCivilities(this.form.tiers_civility)

	                    const params = cloneDeep(this.form)
	                    let new_tiers_id = null
						try {
                            // création du tiers entité
                            new_tiers_id = await this.addTiers(params)

                            // création d'un client test
                            await this.addTiers({
                                tiers_id: Common.getNegativeId(),
                                tiers_rs: "TEST Client",
                                tiers_firstname: "Client",
                                tiers_lastname: "Test",
                                tiers_mail: "client@test.com",
                                tiers_address1: "6 rue de Test",
                                tiers_postalcode: "14000",
                                tiers_town: "Caen",
                                tiers_country: "75",
                                tiers_moral: 1,
                                tiers_entity: 0,
                                tiers_valide: 1,
                                phones: []
                            })
                            // await this.$sync.runOptionalWhishlist(['tiers'])
                            await this.$sync.force(true, true)
                            new_tiers_id = this.$sync.replaceWithReplicated('tiers', new_tiers_id)

			                await this.addAccountingPlan(new_tiers_id, this.form.tiers_rs, 'VE', 'BQ', 1, 'F', 'Y9999', 1, '411', 'SSSSSS99', '401', 'SSSSSS99', true, false, new Date(), false, 'd/m/Y', true)
						}
						catch (e) {
							if (typeof(e) === 'string') {
								this.form_message = e
								this.failureToast(e)
							}
							else {
								console.error(e)
							}

							return null
						}

                        this.processing = false

                        const params_onboarding =  {
                            code: this.code_onboarding,
                            done: 1,
                            skip: 0
                        }

                        this.ok(params_onboarding)
                        this.shutterPanel().close(this.code_onboarding)

					} else {
						this.processing = false
						this.form_message = "formulaire.erreur_champs_non_remplis"
                        window.scrollTo(0, 0) 
					}
				}
            },

			addPhoneNumber() {
				this.form.phones.push({
					phone_indicatif: '+33',
					phone_numero: '',
					phone_type: 'fixe',
					phone_country: 'FRA',
                    phone_libelle: ''
				})
			},
			
			resetTown() {
				this.form.tiers_town = ''
			},

			resetPostalcode() {
				this.form.tiers_postalcode = ''
			},
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
            CurrencyInput : () => import('GroomyRoot/components/Inputs/CurrencyInput'),
            LocaleInput : () => import('@/components/Inputs/LocaleInput'),
            WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
            EAddress : () => import('GroomyRoot/components/Utils/EAddress'),
			SearchInputCommune : () => import('@/components/Inputs/SearchInputCommune')
        }
    }
</script>
